
/* eslint-disable no-console */
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash/debounce';

import uniq from 'lodash/uniq';
import DietAndAllergens from './DietAndAllergens';
import useProductSizes from 'core/hooks/useProductSizes';
import useSubscriptionOptions from 'core/hooks/useSubscriptionOptions';
import useUser from 'core/hooks/useUser';

import { SET_SUBSCRIPTION_OPTIONS , SET_DIET_AND_ALLERGIES_AND_CREATE_SUBSCRIPTION_QUANTITIES } from 'core/api/apollo/mutations';
import { ERROR_MESSAGE } from 'core/api/constants';
import Money from 'core/api/Money';
import Tracker from 'core/api/tracking/tracker';

import { FormGroup, Grid,
} from 'core/ui/atoms';
import { Radio, Checkbox, InputGroupLabel } from 'core/ui/atoms/V2';
import { Loading } from 'core/ui/components';
import handlesErrors from 'core/ui/components/HandlesErrors';

import { FOOD_TYPES, NUM_DAY_OPTIONS_V2, ESTIMATE_MIN_AMOUNT, ESTIMATE_ERROR_AMOUNT } from 'onramp/constants';
import { numDaysLabel, stringWithoutTags } from 'onramp/helpers';
import { ContentButton, ContentForm } from 'onramp/ui/components';

const SUBSCRIPTION_OPTIONS_DEBOUNCE = 500; // ms

function CustomizePlanForm(props) {
  const { onSubmit, onSuccess, disabled } = props;
  const { currentUser } = useUser();
  const { loading, subscriptionOptions = { productSize: { id: null } } } = useSubscriptionOptions();

  return (
    loading ? (
      <Loading />
    ) : (
      <StepForm
        currentUser={currentUser}
        initialData={{ ...subscriptionOptions, productSizeId: subscriptionOptions && subscriptionOptions.productSize && subscriptionOptions.productSize.id }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        disabled={disabled}
      />
    )
  );
}

CustomizePlanForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

@handlesErrors
@graphql(SET_DIET_AND_ALLERGIES_AND_CREATE_SUBSCRIPTION_QUANTITIES, { name: 'createSubscriptionQuantities' })
@graphql(SET_SUBSCRIPTION_OPTIONS, { name: 'setSubscriptionOptions' })
class StepForm extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    createSubscriptionQuantities: PropTypes.func.isRequired,
    setSubscriptionOptions: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      initialData: { breakfast = true, lunch = true, dinner = true, snacks = true, numDays, productSizeId, productSize, estimatedPrice, subscribedDays, startDay },
      currentUser,
    } = this.props;

    const dietId = currentUser.diet && currentUser.diet.id;
    const allergyIds = (currentUser.allergyTags || []).map((tag) => tag.id);

    // set default state
    this.state = {
      breakfast,
      lunch,
      dinner,
      snacks,
      numDays,
      productSizeId,
      productSize,
      estimatedPrice,
      subscribedDays,
      startDay,
      isProcessing: false,
      isSaving: false,
      dietId,
      allergyIds,
      diets: [],
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setStateSafely = (...args) => this.mounted && this.setState(...args);

  isValid = () => {
    const { breakfast, lunch, dinner, snacks, numDays, productSizeId, dietId } = this.state;
    return (breakfast || lunch || dinner || snacks) && numDays && productSizeId && dietId;
  };

  updateSubscriptionOptions = () => {
    this.setStateSafely({ isProcessing: true });

    this.setSubscriptionOptions();
  }

  setSubscriptionOptions = debounce(() => {
    const { isProcessing, isSaving, productSize, estimatedPrice, subscribedDays, startDay, dietId, allergyIds, diets, ...input } = this.state;
    const { setSubscriptionOptions } = this.props;

    if (!this.mounted || isSaving) {
      return;
    }

    if (!this.isValid()) {
      this.setStateSafely({
        productSize: null,
        estimatedPrice: null,
        subscribedDays: null,
        startDay: null,
        isProcessing: false,
      });
      return;
    }

    this.setStateSafely({
      productSize: null,
      estimatedPrice: null,
      subscribedDays: null,
      startDay: null,
      isProcessing: true,
    });

    console.log(input,'input');

    setSubscriptionOptions({ variables: { input } })
    .then(({ data: { setSubscriptionOptions: { subscriptionOptions, errors } } }) => {
      if (errors.length) {
        this.handleError(errors);
      } else {
        const { productSize, estimatedPrice, subscribedDays, startDay } = subscriptionOptions;
        this.setStateSafely({ productSize, estimatedPrice, subscribedDays, startDay });
      }
    }, () => {
      this.handleError([{ message: ERROR_MESSAGE }], true);
    })
    .finally(() => {
      this.setStateSafely({ isProcessing: false });
    });
  }, SUBSCRIPTION_OPTIONS_DEBOUNCE);

  handleError = (errors, displayOnly = false) => {
    this.setStateSafely({ isProcessing: false, isSaving: false });
    this.props.displayApolloResponseErrors(errors, displayOnly);
  };

  updateDietAndAllergiens = (dietId, allergyIds, diets) => this.setStateSafely({
    dietId, allergyIds, diets,
  })

  setField = (name, value) => this.setStateSafely({ [name]: value }, this.updateSubscriptionOptions);

  numDaysLabel = (option) => {
    const { currentUser: { primaryAddress: { deliveryOption: { deliveryMethod } } } } = this.props;
    const label = numDaysLabel(option.value, deliveryMethod);

    return `${label.label}`;
  };

  displayPriceRange = () => {
    const { isProcessing, estimatedPrice } = this.state;
    if (isProcessing) {
      return 'Calculating...';
    }

    const estimate = Money(estimatedPrice).getAmount();
    const min = Math.max(estimate - ESTIMATE_ERROR_AMOUNT, ESTIMATE_MIN_AMOUNT);
    const max = estimate + ESTIMATE_ERROR_AMOUNT;
    return `${Money({ amount: min }).toFormat('$0,0')} - ${Money({ amount: max }).toFormat('$0,0')}`;
  };

  displayDeliveries = () => {
    const { subscribedDays } = this.state;
    if (!(subscribedDays || []).length) {
      return '...';
    }
    return `${subscribedDays.length > 1 ? 'Two deliveries' : 'One delivery'} per week:`;
  }

  displayDeliveryTimes = () => {
    const { currentUser: { primaryAddress: { deliveryOption: { name } } } } = this.props;
    const { subscribedDays } = this.state;
    const days = (subscribedDays || []).map((day) => `${moment().day(day).format('dddd')}s`);

    return (name && days.length) ? `${name} on ${days.join(' and ')}` : '...';
  };

  onSuccess = () => {
    const { breakfast, lunch, dinner, snacks, numDays, productSize, estimatedPrice } = this.state;
    Tracker.stashSubscriptionDetails({ breakfast, lunch, dinner, snacks, numDays, productSize, estimatedPrice: Money(estimatedPrice).toFormat('0.00') });
    const { email, phoneNumber } = this.props.currentUser;
    Tracker.trackAddToCart({ email, phoneNumber });
    this.props.onSuccess();
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.isValid() || this.state.isProcessing || this.props.disabled) {
      return;
    }

    this.setStateSafely({ isSaving: true });
    this.props.onSubmit();
      const diet = this.state.diets.find((diet) => diet.id === this.state.dietId);

    const foodsToAvoidIds = uniq([...this.state.allergyIds,...diet.ingredientTags.map((tag) => tag.id) ]);


    return this.props.createSubscriptionQuantities({
      variables: { createSubscriptionQuantitiesInput: { }, setDietInput: { dietId: this.state.dietId },
      setFoodsToAvoidInput: { allergyIds: this.state.allergyIds, foodsToAvoidIds } },
    }).then(({ data: { createSubscriptionQuantities: { errors } } }) => (
      errors.length ? this.handleError(errors) : this.onSuccess()
    )).finally(() => {
      this.setStateSafely({ isSaving: false });
    });
  };

  render() {
    const { isProcessing, isSaving } = this.state;
    const { disabled } = this.props;

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <CustomFormGroup role="checkbox" aria-labelledby="whichFood" className="flex">
          <InputGroupLabel id='whichFood'>Which Food?</InputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
          {FOOD_TYPES.map((type) => (
            <CustomCheckbox
              key={type.value}
              name="foodType"
              label={type.name}
              checked={this.state[type.value]}
              checkedIcon='minus'
              uncheckedIcon='plus'
              onChange={() => this.setField(type.value, !this.state[type.value])}
            />
          ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-labelledby="daysOfFood">
          <InputGroupLabel id='daysOfFood'>Days Of Food</InputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>

          {NUM_DAY_OPTIONS_V2.map((option) => (
            <CustomRadio
              key={`day-${option.value}`}
              name="numDays"
              label={this.numDaysLabel(option)}
              subLabel={option.description}
              value={option.value}
              checked={this.state.numDays === option.value}
              onChange={() => this.setField('numDays', option.value)}
            />
          ))}
          </Grid>
        </CustomFormGroup>

        <ProductSizeRadios
          selected={this.state.productSizeId}
          onChange={this.setField}
        />

          <DietAndAllergens
            currentUser={this.props.currentUser}
            updateDietAndAllergiens={this.updateDietAndAllergiens}
          />

        <CustomContentButton type="submit" disabled={!this.isValid() || isProcessing || isSaving || disabled}>
          <small>Total $ </small>{this.displayPriceRange()} <small> - CHECKOUT </small>
        </CustomContentButton>
      </ContentForm>
    );
  }
}

function ProductSizeRadios(props) {
  const { selected, onChange } = props;
  const { loading, productSizes } = useProductSizes();

  if (loading) {
    return <Loading />;
  }

  return (
    <CustomFormGroup role="radiogroup" aria-labelledby="mealPortionSize">
      <InputGroupLabel id='mealPortionSize'>Meal Portion Size</InputGroupLabel>
      <Grid cols={'1fr 1fr'} gap={'1.2em'}>

      {productSizes.map((size) =>
        (
          <CustomRadio
          className={size.name.toLowerCase().includes('couple') ?'span_2': 0}
          key={size.id}
          name="productSizeId"
          label={size.name}
          subLabel={stringWithoutTags(size.description)}
          value={size.id}
          checked={selected === size.id}
          onChange={() => onChange('productSizeId', size.id)}
          />
        ))}
        </Grid>
    </CustomFormGroup>
  );
}

ProductSizeRadios.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomRadio = styled(Radio)`
  margin-bottom: 1em;
`;

const CustomCheckbox = styled(Checkbox)`
  margin-bottom: 1em;
`;

const CustomContentButton = styled(ContentButton)`
  display: flex;
  width: 100%;
  padding: 12px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background: #C1D3C1;
  margin-bottom: 80px;
  ${(p) => p.theme.max('sm')`
    margin-bottom: 0;
  `}
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 1.5em;
`;

export default CustomizePlanForm;
