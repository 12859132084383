/* eslint-disable semi */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/parseInt';

import { displayScheduleLabel, stringWithoutTags } from '../../../helpers';
import { programBundleCartVar } from 'core/api/apollo/cache';
import { session } from 'core/api/storage';
import {
  GET_PROGRAM_BUNDLE,
  LIST_PROGRAM_OPTIONS,
  LIST_DELIVERY_DAYS_FOR_DIGITAL_ONLY,
} from 'core/api/apollo/queries';
import { CREATE_TRANSFORMATION_TRIBE } from 'core/api/apollo/mutations';
import { updateUserQuery } from 'core/api/cache/updaters';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import { Form, FormGroup, Select, Grid, Checkbox, SanitizedHTML } from 'core/ui/atoms';
import { InputGroupLabel, Radio } from 'core/ui/atoms/V2';
import { Loading } from 'core/ui/components';
import { displayDate } from 'core/ui/dateHelpers';

import { onrampNext } from 'onramp/api/navigation';
import { PROGRAM_FOOD_OPTIONS, priceLabel } from 'onramp/constants';

import { ContentButton, ContentForm } from 'onramp/ui/components';

const SESSION_PROGRAM_BUNDLE_CART_KEY = 'TransformationTribeProgramPage/Cart';

export const PROGRAM_LENGTHS = [
  { name: '4 Weeks', value: '4', default: false },
  { name: '8 Weeks', value: '8', default: false },
];

// eslint-disable-next-line react/prop-types
const FoodWithDigitalLabel = ({ text }) => (
  <CustomLabel>
    {text} <div>+ Digital Education</div>
  </CustomLabel>
);

function BundleForm(props) {
  const { programId, scheduleId, sizeId, mealTypeId, isDigitalOnly } = props;
  const { displayApolloResponseErrors } = useErrorHandlers();
  const bundle = programBundleCartVar();

  const defaultNumWeeks = (PROGRAM_LENGTHS.find((l) => l.default) || PROGRAM_LENGTHS[0]).value;

  const [numWeeks, setNumWeeks] = useState(bundle.numWeeks || defaultNumWeeks);
  const [bundleId, setBundleId] = useState(null);
  const [startDayId, setStartDayId] = useState('');
  const [dietryRestriction, setDietryRestriction] = useState(false);
  const [timingRestriction, setTimingRestriction] = useState(false);
  const [availableDeliveryDays, setAvailableDeliveryDays] = useState([]);
  const [digOnlyPrice, setDigOnlyPrice] = useState({ amount: 74900, isoCode: 'USD' });
  const [
    getDigitalOnlyDates,
    { refetch: refetchDigitalOnlyDates, called: calledDigitalOnlyDates },
  ] = useLazyQuery(LIST_DELIVERY_DAYS_FOR_DIGITAL_ONLY, {
    onCompleted: (response) => {
      const availableDeliveryDaysList =
        response && response.listDeliveryDaysForDigitalOnly !== undefined
          ? response.listDeliveryDaysForDigitalOnly
          : [];

      // eslint-disable-next-line no-console
      console.log(availableDeliveryDaysList, '3');
      setAvailableDeliveryDays(availableDeliveryDaysList);
    },
  });

  useEffect(() => {
    if (isDigitalOnly) {
      if (calledDigitalOnlyDates) {
        refetchDigitalOnlyDates().then(({ data }) => {
          const availableDeliveryDaysList =
            data && data.listDeliveryDaysForDigitalOnly !== undefined
              ? data.listDeliveryDaysForDigitalOnly
              : [];
          // eslint-disable-next-line no-console
          console.log(availableDeliveryDaysList, '2');
          setAvailableDeliveryDays(availableDeliveryDaysList);
        });
      } else {
        getDigitalOnlyDates();
      }
    }
  }, [isDigitalOnly, getDigitalOnlyDates, calledDigitalOnlyDates, refetchDigitalOnlyDates]);

  const {
    loading,
    refetch: refetchProgramBundle,
    data,
  } = useQuery(GET_PROGRAM_BUNDLE, {
    variables: { programId, scheduleId, sizeId, mealTypeId, numWeeks: parseInt(numWeeks) },
    onCompleted: (response) => {
      if (response && response.getProgramBundle && response.getProgramBundle.digitalOnlyPrice) {
        setDigOnlyPrice(response.getProgramBundle.digitalOnlyPrice);
      }
      const bundleId = response && response.getProgramBundle ? response.getProgramBundle.id : null;
      // eslint-disable-next-line no-unused-vars
      setBundleId((_) => bundleId);

      const availableDeliveryDaysList =
        response &&
        response.getProgramBundle &&
        response.getProgramBundle.ttAvailableDeliveryDays !== undefined
          ? data.getProgramBundle.ttAvailableDeliveryDays
          : [];

      setAvailableDeliveryDays(availableDeliveryDaysList);
    },
    notifyOnNetworkStatusChange: true,
  });

  let price =
    data && data.getProgramBundle && data.getProgramBundle.price !== undefined
      ? data.getProgramBundle.price
      : 0;

  price = isDigitalOnly ? digOnlyPrice : price;

  const resetState = () => {
    setBundleId(() => null);

    // const event_date = availableDeliveryDays &&  availableDeliveryDays.find((d) => d.dateWithoutOffset === '2024-05-06');
    const default_date = '';

    setStartDayId(default_date);

    refetchProgramBundle();
  };
  useEffect(resetState, [props, refetchProgramBundle, numWeeks]);
  // CreateTransformationTribe
  const [createTransformationTribe, { loading: isSaving }] = useMutation(
    CREATE_TRANSFORMATION_TRIBE,
    {
      update: (cache, { data }) => {
        const {
          createTransformationTribe: { oneTimeProgram, errors },
        } = data;
        updateUserQuery(
          cache,
          { user: { nextOneTimeProgram: { bundleReservations: null } } },
          errors,
        ); // clear the reservations array
        updateUserQuery(cache, { user: { nextOneTimeProgram: { ...oneTimeProgram } } }, errors);
      },
    },
  );
  const disabled =
    ([bundleId, numWeeks, startDayId].some(isEmpty) ||
      isSaving ||
      !dietryRestriction ||
      !timingRestriction ||
      !price) &&
    (!isDigitalOnly || !startDayId);

  const onServerError = (error) => {
    const errors = [error].flatten();
    displayApolloResponseErrors(errors);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = { programId, sizeId, mealTypeId, scheduleId, numWeeks, bundleId, startDayId };
    session.setItem(SESSION_PROGRAM_BUNDLE_CART_KEY, JSON.stringify(data));
    programBundleCartVar(data);

    const input = {
      programBundleId: bundleId,
      numWeeks: toInteger(numWeeks),
      startDayId: startDayId,
      digitalOnly: isDigitalOnly,
    };
    createTransformationTribe({ variables: { input } })
      .then(({ data }) => {
        const {
          createTransformationTribe: { errors = [] },
        } = data;
        errors.length ? onServerError(errors) : props.onSuccess();
      })
      .catch(({ errors = [] }) => {
        if (errors.length) {
          onServerError(errors);
        }
      });
  };

  const formatDateOption = useCallback(
    (availableDeliveryDays) =>
      availableDeliveryDays.map((d) => {
        const title = `${displayDate(d.dateWithoutOffset, 'deliveryDate')}${
          d.locked || d.decOff ? ' (SOLD OUT)' : ''
        }`;

        return {
          title,
          value: d.id,
          disabled: d.locked || d.decOff,
        };
      }),
    [],
  );

  return (
    <ContentForm onSubmit={onSubmit} noValidate instantValidate={false}>
      <CustomFormGroup
        role="radiogroup"
        aria-labelledby="program_length"
        isDigitalOnly={isDigitalOnly}
      >
        <InputGroupLabel id="program_length">Weeks of Food Delivery</InputGroupLabel>
        <Grid gap="1.2em">
          {PROGRAM_LENGTHS.map((option) => (
            <Radio
              key={`numWeeks-${option.value}`}
              name="numWeeks"
              label={option.name}
              value={option.value}
              subLabel={option.subLabel}
              disable={isDigitalOnly}
              checked={!isDigitalOnly && !!numWeeks && numWeeks === option.value}
              onChange={(e) => setNumWeeks(e.target.value)}
            />
          ))}
        </Grid>
      </CustomFormGroup>

      <CustomFormGroup
        isDigitalOnly={isDigitalOnly}
        role="checkbox"
        aria-labelledby="dietaryRestrictions"
      >
        <InputGroupLabel id="dietaryRestrictions">Order and Dietary Restrictions</InputGroupLabel>
        <CustomCheckbox
          label="I understand that you can’t accommodate food allergies or dietary restrictions, with the exception of allowing up to two meal swaps per week. I also understand that your menu is free of gluten and dairy."
          name="dietryRestriction"
          value="dietryRestriction"
          onChange={() => setDietryRestriction((value) => !value)}
          checked={!isDigitalOnly && dietryRestriction}
          disable={isDigitalOnly}
        />
      </CustomFormGroup>

      <CustomFormGroup isDigitalOnly={isDigitalOnly}>
        <CustomCheckbox
          label={
            <span>
              I understand that if I need to modify or postpone my order, I must email
              <InnerLink href="mailto:help@gomethodology.com"> help@gomethodology.com</InnerLink> by
              9 PM PT on the Sunday one week before my scheduled delivery, and any requests after
              that cannot be accommodated.
            </span>
          }
          name="timingRestriction"
          value="timingRestriction"
          onChange={() => setTimingRestriction((value) => !value)}
          checked={!isDigitalOnly && timingRestriction}
          disable={isDigitalOnly}
        />
      </CustomFormGroup>

      <CustomFormGroup>
        <InputGroupLabel>Start Date</InputGroupLabel>
        {!loading ? (
          <CustomSelect
            name="startDayId"
            options={formatDateOption(availableDeliveryDays)}
            selected={startDayId}
            onChange={(e) => setStartDayId(e.target.value)}
            prompt="Select a start date"
          />
        ) : (
          <CustomSelect
            name="startDayId"
            options={[]}
            selected={''}
            prompt="Loading..."
            readOnly={true}
          />
        )}
      </CustomFormGroup>

      <CustomContentButton
        type="submit"
        disabled={loading || (disabled && !(isDigitalOnly && startDayId))}
      >
        <div aria-live="polite">
          Total {loading ? '...' : priceLabel(price, numWeeks, isDigitalOnly)} - CHECKOUT{' '}
        </div>
      </CustomContentButton>
    </ContentForm>
  );
}

BundleForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  sizeId: PropTypes.string.isRequired,
  mealTypeId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  scheduleId: PropTypes.string.isRequired,
  isDigitalOnly: PropTypes.bool,
};

//--------------

export function StepForm(props) {
  const { onSuccess } = props;
  const bundle = programBundleCartVar();
  const [programId, setProgramId] = useState(bundle.programId);
  const [sizeId, setSizeId] = useState(bundle.sizeId);
  const [scheduleId, setScheduleId] = useState(bundle.scheduleId);
  const [mealTypeId, setMealTypeId] = useState(bundle.mealTypeId);
  const [schedulesList, setSchedulesList] = useState([]);
  const [mealTypesList, setMealTypes] = useState([]);
  const [filteredSchedulesList, setFilteredSchedulesList] = useState([]);

  const programFoods = PROGRAM_FOOD_OPTIONS;

  useEffect(() => {
    const data = JSON.parse(session.getItem(SESSION_PROGRAM_BUNDLE_CART_KEY) || '{}');
    const { programId, sizeId, scheduleId, numWeeks, bundleId, startDayId, mealTypeId } = data;
    programBundleCartVar({
      programId,
      sizeId,
      scheduleId,
      numWeeks,
      bundleId,
      startDayId,
      mealTypeId,
    });
    setProgramId(programId);
    setSizeId(sizeId);
    // Set setScheduleId(scheduleId) if the need is to show preselected schedule, passed from other program.
    setScheduleId(null);
    setMealTypeId(mealTypeId);
  }, []);

  useEffect(() => {
    if (schedulesList.length && mealTypeId) {
      const filteredSchedules = schedulesList.filter((schedule) =>
        schedule.name === '4 Days' || schedule.name === '5 Days',
      );
      const selectedFoodPreference = mealTypesList.find((f) => f.id === mealTypeId);
      let filteredList = filteredSchedules.map((s) => {
        if (selectedFoodPreference && selectedFoodPreference.tags) {
          return selectedFoodPreference.tags.includes(s.name) ? s : { ...s, disable: true };
        }
        return s;
      });

      if (!filteredList.length) {
        filteredList = schedulesList;
      }

      setFilteredSchedulesList(sortBy(filteredList, 'name'));
      
      // Uncomment below lines to enable default schedule selection.
      // const defaultSchedule = filteredList.find((s) => s.default || !s.disable);
      // setScheduleId(defaultSchedule.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealTypeId, programFoods, schedulesList]);

  // TODO: Implement this as a real attribute on Programs::PrepaidBundle

  const {
    loading,
    data: {
      listPrepaidPrograms: programs,
      // eslint-disable-next-line no-unused-vars
      listProgramSchedules: programSchedules,
      listProgramSizes: programSizes,
    } = {},
  } = useQuery(LIST_PROGRAM_OPTIONS, {
    onCompleted: ({
      listPrepaidPrograms: programs,
      listProgramSchedules: schedules,
      listProgramSizes: sizes,
      listProgramMealTypes: mealTypes,
    }) => {
      const isProgramIdPresent = programOptions.some((program) => program.id === programId);
      if (!isProgramIdPresent && programs) {
        //comment out below code to Disable to AUTOSELECT program.
        const defaultProgram = programOptions.find((p) => p.default) || programOptions[0];
        setProgramId(defaultProgram.id);
      }
      if (!sizeId && sizes) {
        const defaultSize = sizes.find((s) => s.default) || sizes[0];
        setSizeId(defaultSize.id);
      }
      if (schedules) {
        setSchedulesList(schedules);
      }
      if (mealTypes) {
        const defaultFood = mealTypes.find((s) => s.default) || mealTypes[0];
        const newMealTypes = mealTypes.slice().map((m) => {
          let tags = [];
          if (m.name.includes('Without')) {
            // TODO: SET AN Identifier;
            tags = ['5 Days'];
          } else {
            tags = ['4 Days', '3 Days', '2 Days', '1 Day'];
          }
          return { ...m, tags, name: <FoodWithDigitalLabel text={m.name} /> };
        });
        const digitalOnlyMealType = {
          id: 'digital',
          description: '<strong>Digital Education Only</strong><br/><p>(No food)</p>',
          value: 'digital-only',
          tags: [],
        };

        setMealTypes([...newMealTypes, digitalOnlyMealType]);
        if (!mealTypeId) {
          setMealTypeId(defaultFood.id);
        }
      }
    },
  });

  const programOptions = sortBy(programs, 'position').filter((item) => !item.name.toLowerCase().includes('haute mom'));
  const sizeOptions = sortBy(programSizes, 'position').filter(
    (size) => !size.name.toLowerCase().includes('couple'),
  );

  const isDigitalOnly = mealTypeId === 'digital';
  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <Form>
        <CustomFormGroup role="radiogroup" aria-labelledby="foodType">
          <CustomInputGroupLabel id="foodType">Which Meals?</CustomInputGroupLabel>
          <Grid gap="1.2em">
            {mealTypesList.map((option) => (
              <CustomRadio
                key={`food-${option.id}`}
                name="food"
                label={<SanitizedHTML rawHTML={option.description} />}
                value={option.id}
                checked={!!mealTypeId && mealTypeId === option.id}
                onChange={(e) => setMealTypeId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup
          role="radiogroup"
          aria-labelledby="portion_size"
          isDigitalOnly={isDigitalOnly}
        >
          <CustomInputGroupLabel id="portion_size">Meal Portion Size</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
            {sizeOptions.map((option) => (
              <Radio
                className={option.name.toLowerCase().includes('couple') ? 'span_2' : ''}
                key={`size-${option.id}`}
                name="size"
                value={option.id}
                label={option.name}
                subLabel={stringWithoutTags(option.description)}
                disable={isDigitalOnly}
                checked={!isDigitalOnly && !!sizeId && sizeId === option.id}
                onChange={(e) => setSizeId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-label="daysPerWeek" isDigitalOnly={isDigitalOnly}>
          <CustomInputGroupLabel id="daysPerWeek">Days of Food</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1.2em'}>
            {filteredSchedulesList.map((option) => (
              <Radio
                key={`schedule=${option.id}`}
                name="schedule"
                label={displayScheduleLabel(option)}
                value={option.id}
                disable={option.disable || isDigitalOnly}
                checked={!isDigitalOnly && !!scheduleId && scheduleId === option.id}
                onChange={(e) => setScheduleId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>

        <CustomFormGroup role="radiogroup" aria-labelledby="dietType" isDigitalOnly={isDigitalOnly}>
          <CustomInputGroupLabel id="dietType">Diet</CustomInputGroupLabel>
          <Grid cols={'1fr 1fr'} gap={'1em'}>
            {programOptions.map((option) => (
              <Radio
                key={`program-input-${option.id}`}
                name="program"
                label={option.name}
                value={option.id}
                disable={isDigitalOnly}
                checked={!isDigitalOnly && !!programId && programId === option.id}
                onChange={(e) => setProgramId(e.target.value)}
              />
            ))}
          </Grid>
        </CustomFormGroup>
      </Form>

      {(isDigitalOnly || (programId && sizeId && mealTypeId && scheduleId)) && (
        <BundleForm
          currentUser={props.currentUser}
          onSuccess={onSuccess}
          programId={programId}
          sizeId={sizeId}
          mealTypeId={mealTypeId}
          scheduleId={scheduleId}
          isDigitalOnly={isDigitalOnly}
        />
      )}
    </Fragment>
  );
}

StepForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

function SignatureProgramPage(props) {
  const onSuccess = () => onrampNext(props.currentUser, props.cache);

  return <StepForm onSuccess={onSuccess} currentUser={props.currentUser} cache={props.cache} />;
}

SignatureProgramPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

const CustomContentButton = styled(ContentButton)`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  width: 100%;
  padding: 12px 35px;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background: #c1d3c1;
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 1.5em;
  opacity: ${(props) => (props.isDigitalOnly ? 0.4 : 1)};
`;

const CustomRadio = styled(Radio)`
  div#heading {
      max-width: 60%;
  }
`;

const CustomSelect = styled(Select)`
  margin-bottom: 1em;

  p.select-selected {
    margin: 0.5em 0 2.5em;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  .checkbox {
    margin-right: 1em;
    font-size: ${(p) => p.theme.typography.sizes.big};
    align-item: top;
  }
`;

const CustomInputGroupLabel = styled(InputGroupLabel)`
  padding-bottom: 0em;
`;

const CustomLabel = styled.span`
  font-weight: 300 !important;
  & div {
    font-weight: 500 !important;
  }
`;

const InnerLink = styled.a`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default SignatureProgramPage;
