/* eslint-disable no-console */
import React, { Component, PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserDetailsForm from './UserDetailsForm';
import Money from 'core/api/Money';
import { ERROR_MESSAGE , PHONE_REGEXP } from 'core/api/constants';
import { ROUTE_MAP } from 'onramp/api/routeSequence';
import { GET_BILLING_INFO, GET_SUBSCRIPTION_PURCHASE, GET_PREPAID_PURCHASE } from 'core/api/apollo/queries';
import client from 'core/api/apollo/apolloClient';
import {
  SET_BILLING_INFO,
  SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_CREDIENTIALS,
} from 'core/api/apollo/mutations';
import { updateUserQuery } from 'core/api/cache/updaters';
import {
  ControlsGrid,
  FormGroup,
  H2,
  Label,
  StripeCardInput,
  StripeExpiryInput,
  StripeCVCInput,
  Hint, Box,
} from 'core/ui/atoms';
import handlesErrors from 'core/ui/components/HandlesErrors';
import { Loading } from 'core/ui/components';
import StripeContext from 'core/ui/components/Stripe/Context';

import { ContentForm, ContentSection, InfoHeader } from 'onramp/ui/components';

import {
  PurchaseSummary,
  ProgramSummary,
  Page,
  PageInfo,
  PageContent,
  ContentButton,
} from 'onramp/ui/components/V2';
import { formatPhoneNumber } from 'core/api/utils';


import { userHasCourierAddress } from 'core/api/Account';

import withForm from 'core/ui/components/withForm';

@handlesErrors
@graphql(SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_CREDIENTIALS)
@withForm('setUserDeliveryInfo')
class ContainerPage extends Component {
  state = {
    loading: true,
    data: null,
  };

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    cache: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    handleGenericApolloError: PropTypes.func.isRequired,
    location: PropTypes.object,
    form: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    setUserValidateEmail: PropTypes.func,
    userValidateEmail: PropTypes.string,
    setIsProcessing: PropTypes.func,
    isSubscribed: PropTypes.bool,
    refetchUser: PropTypes.func,
  };

  fetchData = () => {
    const query = this.isShippingFlow ? GET_PREPAID_PURCHASE : GET_SUBSCRIPTION_PURCHASE;

    client
      .query({ query, fetchPolicy: 'network-only' })
      .then((response) => {
        this.setState({ data: response.data, loading: false });
        console.log(response.data, 'inside fetchData');
      })
      .catch((error) => {
        this.setState({ error, loading: false });
        console.error(error);
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  isValid = () => {
    const {
      currentUser,
      form: {
        formData: { street1, city, instructions, phoneNumber, zipCode,  email = '' },
      },
    } = this.props;
    const instructionsInvalid = userHasCourierAddress(currentUser) && !instructions;
    if (!street1 || !city || instructionsInvalid || !phoneNumber || !zipCode) {
      return false;
    }
    if (this.userRequired) {
      if (
        !(
          email.length
        )
      )
      return false;
    }
    const formatted = formatPhoneNumber(phoneNumber) || phoneNumber;
    return formatted.match(new RegExp(PHONE_REGEXP));
  };

  onError = (...props) => {
    this.props.setIsProcessing(false);
    this.props.handleGenericApolloError(...props);
  }

  onUserAndAddressSubmit = () => {
    const { mutate } = this.props;
    const {
      form: {
        formData,
      },
    } = this.props;

    const userDetails = {};
    if (formData.email) {
      userDetails.email = formData.email;
    }
    if (formData.password) {
      userDetails.password = formData.password;
      userDetails.passwordConfirmation = formData.password;
    }

    return mutate({
      variables: {
        setDeliveryInfoInput: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          street1: formData.street1,
          street2: formData.street2,
          city: formData.city,
          instructions: formData.instructions,
          phoneNumber: formData.phoneNumber,
          zipcode: formData.zipCode,
        },
        setSmsPreferencesInput: {
          receiveReminders: formData.receiveReminders,
        },
        setAccountCredentialsInput: {
          ...userDetails,
        },
      },
      onError: this.onError,
    }).then(({ data }) => {

      const { errors } = data;
      if (errors && errors.length) {

        this.onError(errors);
      } else {
        this.props.onComplete();
      }
    }).catch(() => {
      this.onError([{ message: ERROR_MESSAGE }]);
    });
  };

  isShippingFlow = !this.props.isSubscribed;

  render() {
    return (
      <CustomPage backgroundColor="#FFF8F0">
        <CustomInfoHeader>Program Summary</CustomInfoHeader>
        <Box hiddenMinSM>
          <CustomContentSection spaced highlight>
            <ProgramSummary
              returnTo={ROUTE_MAP.newBillingInfo.name}
              isShipping={this.isShippingFlow}
            />
          </CustomContentSection>
        </Box>
        <CustomPageInfo hideBackButton={true}>
          <UserDetailsForm
            setUserValidateEmail={this.props.setUserValidateEmail}
            userValidateEmail={this.props.userValidateEmail}
            isValid={this.isValid}
            form={this.props.form}
            currentUser={this.props.currentUser}
            refetchUser={this.props.refetchUser}
            cache={this.props.cache}
          />
        </CustomPageInfo>
        <Box hiddenMinSM>
          <CustomContentForMobileSection spaced>
            {this.state.data &&
              <PurchaseSummary
                enablePromoCodeForm={!!this.props.userValidateEmail}
                isShipping={this.isShippingFlow}
                data={this.state.data}
                refetch={this.fetchData}
              />
            }
          </CustomContentForMobileSection>
        </Box>
        <CustomPageContent>
          <Box hiddenSM>
            <CustomContentSection spaced>
              <ProgramSummary
                returnTo={ROUTE_MAP.newBillingInfo.name}
                isShipping={this.isShippingFlow}
                getPrepaidPurchase={this.state.data && this.state.data.getPrepaidPurchase}
              />
              {this.state.data &&
              <PurchaseSummary
                enablePromoCodeForm={!!this.props.userValidateEmail}
                isShipping={this.isShippingFlow}
                data={this.state.data}
                refetch={this.fetchData}
              />
            }
            </CustomContentSection>
          </Box>
          <Box>
          <StripeContext>
            <StepForm
              disabled={!this.props.userValidateEmail}
              onSuccess={this.onUserAndAddressSubmit}
              inShippingFlow={this.isShippingFlow}
              userDataValid={this.isValid}
              setIsProcessing={this.props.setIsProcessing}
              total = {this.state.data && (this.isShippingFlow ? this.state.data.getPrepaidPurchase.total : this.state.data.getSubscriptionPurchase.total)}
              />
          </StripeContext>
          </Box>
        </CustomPageContent>
      </CustomPage>
    );
  }
}

@handlesErrors
@graphql(GET_BILLING_INFO)
@graphql(SET_BILLING_INFO, {
  options: {
    update: (
      cache,
      {
        data: {
          setBillingInfo: { billingAccount, errors },
        },
      },
    ) => {
      updateUserQuery(cache, { user: { billingAccount } }, errors);
    },
  },
})
class StepForm extends PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    stripe: PropTypes.object,
    elements: PropTypes.object,
    data: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
    getCardElement: PropTypes.func,
    inShippingFlow: PropTypes.bool.isRequired,
    userDataValid: PropTypes.func,
    setIsProcessing: PropTypes.func,
    total: PropTypes.object,
  };

  state = {
    ready: false,
    isSubmitted: false,
    isProcessing: false,
  };

  setIsProcessing = () => this.setState({ isProcessing: true });
  unsetIsProcessing = () => this.setState({ isProcessing: false });
  setIsSubmitted = () => this.setState({ isSubmitted: true });
  unsetIsSubmitted = () => this.setState({ isSubmitted: false });

  // the form isn't ready until at least one of the stripe input fields is ready.
  // if the form is submitted before this, the page will throw an error.
  isReady = () => this.state.ready;

  setReady = () => this.setState({ ready: true });

  saveBillingInfo = (token) => {
    const { mutate, onSuccess } = this.props;

    mutate({ variables: { input: { stripeToken: token.id } } })
      .then(
        ({
          data: {
            setBillingInfo: { errors },
          },
        }) => {
          errors.length ? this.onServerError(errors) : onSuccess();
        },
      )
      .catch(({ error }) => {
        this.onServerError(error);
      });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { stripe, elements, getCardElement, setIsProcessing: setIsProcessingOverlay } = this.props;

    this.setIsSubmitted();
    this.setIsProcessing();

    if (!stripe || !elements) {
      this.onError();
      return;
    }

    setIsProcessingOverlay(true);

    stripe.createToken(getCardElement()).then(({ error, token }) => {
      error ? this.onServerError(error) : this.saveBillingInfo(token);
    });

  };

  onError = () => {
    this.unsetIsSubmitted();
    this.unsetIsProcessing();
    this.props.setIsProcessing(false);
  };

  onServerError = (error) => {
    this.onError();
    const errors = [error].flatten();
    this.props.displayApolloResponseErrors(errors);

  };

  render() {
    const {
      inShippingFlow,
      data: { loading },
    } = this.props;
    const { isSubmitted } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <ContentForm onSubmit={this.onSubmit} noValidate instantValidate={false}>
        <CustomPaymentDetailSection spaced highlight>
        <H2 no-margin>
            Payment Details
          </H2>
          <Hint>Fields marked with * are required.</Hint>
          <FormGroup>
            <Label required>Card Number</Label>
            <StripeCardInput
              placeholder="ex. 4111 1111 1111 1111"
              onReady={this.setReady}
              showInvalidState={isSubmitted}
            />
          </FormGroup>
          <CustomControlsGrid>
            <FormGroup>
              <Label required>Exp date</Label>
              <StripeExpiryInput placeholder="ex. 11/23" showInvalidState={isSubmitted} />
            </FormGroup>
            <FormGroup>
              <Label required>CVC</Label>
              <StripeCVCInput placeholder="ex. 123" showInvalidState={isSubmitted} />
            </FormGroup>
          </CustomControlsGrid>
        </CustomPaymentDetailSection>
        {this.props.total &&
          <CustomContentButton type="submit" disabled={!this.isReady() || this.state.isProcessing || !this.props.userDataValid()}>
            <div aria-live="polite">
              {(inShippingFlow) ?
                `TOTAL ${Money(this.props.total).toFormat()} - PAY NOW` :
                `TOTAL ${Money(this.props.total).toFormat()} - SUBSCRIBE`
              }
            </div>
        </CustomContentButton>}
      </ContentForm>
    );
  }
}


const CustomPage = styled(Page)`
  margin-top: 60px;
`;

const CustomControlsGrid = styled(ControlsGrid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
    grid-gap: 0px;
  `};
`;


const CustomPageInfo = styled(PageInfo)`
  padding: 1rem;
  padding-left: 3rem;
  ${(p) => p.theme.max('sm')`
    padding: 1rem;
  `};
`;

const CustomPageContent = styled(PageContent)`
  padding-top: 0;
`;

const CustomContentSection = styled(ContentSection)`
  margin-bottom: 0;
  ${(p) => p.theme.max('sm')`
    padding: 0rem 2rem 2rem;
    background-color: ${(p) => p.theme.colors.creamWhite};
    margin-bottom: 0;
  `};
`;

const CustomContentForMobileSection = styled(ContentSection)`
  ${(p) => p.theme.max('sm')`
    padding: 2rem 2rem;
    background-color: ${(p) => p.theme.colors.creamWhite};
    margin-bottom: 0;

    h5 {
      margin-top: 0;
    }
  `};
`;

const CustomPaymentDetailSection = styled(ContentSection)`
  background-color: inherit;
  ${(p) => p.theme.max('sm')`
    margin-bottom: 0;
    padding-top: 1rem;
  `};
`;

const CustomInfoHeader = styled(InfoHeader)`
  grid-column: span 2;
  text-align: center;
  padding: 2rem 0;

  ${(p) => p.theme.max('sm')`
    grid-column: span 1;
    padding: 1.5rem 0;
    background-color: ${(p) => p.theme.colors.creamWhite};
  `};
`;

const CustomContentButton = styled(ContentButton)`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  width: 100%;
  padding: 12px 35px;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  border: none;
  background: #c1d3c1;
`;

export default ContainerPage;
