import stateData from 'datasets-us-states-abbr-names';
import AMERICAN_EXPRESS_LOGO from 'core/assets/images/americanexpress.png';
import DINERS_CLUB_LOGO from 'core/assets/images/dinersclub.png';
import DISCOVER_LOGO from 'core/assets/images/discover.png';
import JCB_LOGO from 'core/assets/images/jcb.png';
import MASTERCARD_LOGO from 'core/assets/images/mastercard.png';
import UNKNOWN_LOGO from 'core/assets/images/unknown.png';
import VISA_LOGO from 'core/assets/images/visa.png';

export const CARD_LOGOS = {
  amex: AMERICAN_EXPRESS_LOGO,
  diners: DINERS_CLUB_LOGO,
  discover: DISCOVER_LOGO,
  jcb: JCB_LOGO,
  mastercard: MASTERCARD_LOGO,
  unknown: UNKNOWN_LOGO,
  visa: VISA_LOGO,
};

export const PHONE_REGEXP = '^(\\([2-9]\\d{2}\\) |[2-9]\\d{2}-)\\d{3}-\\d{4}$';
export const ZIP_CODE_REGEXP = '^\\d{5}$';

export const ERROR_MESSAGES = {
  generic: 'Uh ohhhh! An unexpected error occurred. We have already been notified and will solve the issue as soon as possible.',
  required: 'This field is required',
  email: 'Email is not valid',
  numeric: 'Only digits allowed',
  phone: 'Should be a valid phone number',
  zip: 'Should be a valid zip code',
  chooseValue: 'Please choose value',
  chooseRadio: 'Please choose one value',
  chooseCheckbox: 'Please choose one or several values',
  chooseAdultsOrKids: 'Please provide a number of people',
  passwordMatch: 'Passwords should match',
  passwordLength: 'Must contain a minimum of 8 characters and a maximum of 128 characters',
};

export const HELP_EMAIL = 'help@gomethodology.com';

export const EXTERNAL_LINKS = {
  home: 'https://www.gomethodology.com',
  ourStory: 'https://www.gomethodology.com/our-story',
  faq: 'https://www.gomethodology.com/faq',
  contact: 'https://www.gomethodology.com/contact',
  contactMail: `mailto:${HELP_EMAIL}`,
  sourcing: 'https://www.gomethodology.com/sourcing',
  sampleMenu: 'https://www.gomethodology.com/sample-menu',
  helpCenter: 'https://intercom.help/methodology',
  programs: 'https://www.gomethodology.com/client-programs',
  instagram: 'https://www.instagram.com/gomethodology/',
  privacyPolicy: 'https://www.gomethodology.com/privacy-policy',
  shop: 'https://shop.gomethodology.com',
  termsAndConditions: 'https://www.gomethodology.com/terms-conditions',
  shopifyStore: 'https://programs.gomethodology.com/',
  signatureProgram: 'https://www.gomethodology.com/signature',
  postPartumHauteMomProgram: 'https://www.gomethodology.com/postpartum',
  transformationTribeProgram: 'https://www.gomethodology.com/transformation',
};

export const GENDERS = [
  { title: 'Male', value: 'MALE' },
  { title: 'Female', value: 'FEMALE' },
  { title: 'Other', value: 'OTHER' },
];


export const US_STATES = Object.keys(stateData).map((abbreviation) => ({ abbreviation, name: stateData[abbreviation] }));

export const ADDRESS_CHANGE_EMAIL_SUBJECT = 'Shipping address change';
export const ADDRESS_CHANGE_EMAIL_BODY = 'Hello, I want to change my shipping address to ...';

export const ERROR_MESSAGE = `
                We are unable to process your request now.
                Please try again after some time or contact ${HELP_EMAIL}`;
